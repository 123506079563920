<template>
  <div >
    <div class="pd-5">
      <div class=" text-center pt-5">
        <i class="fas fa-address-card icone-principal"></i>
        <h3>Editar dados</h3>
        <div class="h-line" style="width: 142px"></div>
        <p class="mt-3 editar-conta">
          Aqui você pode editar os dados da sua conta como: Nome, Telefone e
          e-mail.
        </p>
      </div>
    </div>
    <hr class="mt-1" />

    <div class="editar-conta">
      <div class="form-group">
        <ValidationProvider
          name="nome"
          rules="required"
          v-slot="{ errors, classes }"
        >
          <label>Nome</label>
          <input
            type="text"
            class="form-control"
            v-model="nome"
            :class="classes"
            data-test-id="editar-perfil-nome"
          />
          <span class="invalid-feedback">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div class="form-group">
        <ValidationProvider
          name="email"
          rules="required"
          v-slot="{ errors, classes }"
        >
          <label>E-mail</label>
          <input
            type="text"
            class="form-control"
            v-model="email"
            :class="classes"
            data-test-id="editar-perfil-email"
          />
          <span class="invalid-feedback">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div class="form-group">
        <ValidationProvider
          name="telefone"
          rules="required"
          v-slot="{ errors, classes }"
        >
          <label>Telefone</label>
          <input
            type="text"
            class="form-control"
            v-model="telefone"
            v-mask="['(##) ####-####', '(##) #####-####']"
            :class="classes"
            data-test-id="editar-perfil-telefone"
          />
          <span class="invalid-feedback">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="text-center ">
        <b-button
          class="salvar-dados  my-3"
          variant="success"
          v-if="nome && email && telefone"
          @click="update"
        >
          <span v-if="updating">Editando...</span>
          <span v-else>Salvar dados pessoais</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "../../auth";
import formatacaoTelefone from "../../helpers/formatacaoTelefone";
import captureError from "../../helpers/captureError";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
export default {
  data() {
    return {
      updating: false,
      edit: false,
      foto: undefined,
      nome: undefined,
      email: undefined,
      telefone: undefined,
    };
  },
  created() {
    this.populateUserInfo();
  },

  methods: {
    populateUserInfo() {
      var userInfo = auth.getUserInfo();
      if (!userInfo) {
        this.$router.replace("/login");
      }
      if (userInfo) {
        this.foto = userInfo.foto;
        this.nome = userInfo.nome || "Não informado";
        this.email = userInfo.email || "Não informado";
        this.telefone = formatacaoTelefone(userInfo.celular) || "Não informado";
      }
    },
    async update() {
      this.updating = true;
      try {
        var userInfo = auth.getUserInfo();

        var data = {
          Id: userInfo.id,
          Nome: this.nome,
          Email: this.email,
          Telefone: this.telefone,
        };

        await axios.put(`${config.API_URLV2}/cliente/`, data);

        userInfo.nome = data.Nome;
        userInfo.email= this.email;
        userInfo.celular= this.telefone;
        auth.setUserInfo(userInfo);

        Swal.fire({
          title: "Perfil alterado com sucesso",
          icon: "success",
        });

        this.updating = false;
        this.edit = false;
      } catch (error) {
        this.updating = false;
        this.edit = false;
        if (error.response && error.response.data) {
          var msg = error.response.data.Message;
          Swal.fire({
            title: "Oops...",
            text: msg,
            icon: "error",
            confirmButtonText: "Ok",
          });
        } else {
          captureError(error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text:
              "Não foi possível alterar o perfil. Tente novamente mais tarde ou contate o suporte.",
          });
          this.edit = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.form-control {
  height: calc(1.5em + 1rem + 5px);
  font-size: 1.1rem;
}
.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.row {
  margin: 0px;
}
.editar-conta {
  padding-left: 150px;
  padding-right: 150px;
  padding-right: 150px;
}
.salvar-dados {
  width: 50%;
  padding: 12px;
  border-radius: 10px;
}
@media (max-width: 664px) {
  .editar-conta {
    padding-left: 50px;
    padding-right: 50px;
    padding-right: 50px;
  }
  .salvar-dados {
    width: 100%;
  }
}
</style>
