var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('hr',{staticClass:"mt-1"}),_c('div',{staticClass:"editar-conta"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',[_vm._v("Nome")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nome),expression:"nome"}],staticClass:"form-control",class:classes,attrs:{"type":"text","data-test-id":"editar-perfil-nome"},domProps:{"value":(_vm.nome)},on:{"input":function($event){if($event.target.composing){ return; }_vm.nome=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',[_vm._v("E-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:classes,attrs:{"type":"text","data-test-id":"editar-perfil-email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',[_vm._v("Telefone")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.telefone),expression:"telefone"},{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) #####-####']),expression:"['(##) ####-####', '(##) #####-####']"}],staticClass:"form-control",class:classes,attrs:{"type":"text","data-test-id":"editar-perfil-telefone"},domProps:{"value":(_vm.telefone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.telefone=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"text-center"},[(_vm.nome && _vm.email && _vm.telefone)?_c('b-button',{staticClass:"salvar-dados my-3",attrs:{"variant":"success"},on:{"click":_vm.update}},[(_vm.updating)?_c('span',[_vm._v("Editando...")]):_c('span',[_vm._v("Salvar dados pessoais")])]):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pd-5"},[_c('div',{staticClass:"text-center pt-5"},[_c('i',{staticClass:"fas fa-address-card icone-principal"}),_c('h3',[_vm._v("Editar dados")]),_c('div',{staticClass:"h-line",staticStyle:{"width":"142px"}}),_c('p',{staticClass:"mt-3 editar-conta"},[_vm._v(" Aqui você pode editar os dados da sua conta como: Nome, Telefone e e-mail. ")])])])}]

export { render, staticRenderFns }